// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("/Users/jamesonknoll/development/mentorverse/blueprint/blueprint-client/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-consulting-main-js": () => import("/Users/jamesonknoll/development/mentorverse/blueprint/blueprint-client/src/pages/consulting/main.js" /* webpackChunkName: "component---src-pages-consulting-main-js" */),
  "component---src-pages-contact-main-js": () => import("/Users/jamesonknoll/development/mentorverse/blueprint/blueprint-client/src/pages/contact/main.js" /* webpackChunkName: "component---src-pages-contact-main-js" */),
  "component---src-pages-index-js": () => import("/Users/jamesonknoll/development/mentorverse/blueprint/blueprint-client/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-products-academic-mentorship-js": () => import("/Users/jamesonknoll/development/mentorverse/blueprint/blueprint-client/src/pages/products/academic-mentorship.js" /* webpackChunkName: "component---src-pages-products-academic-mentorship-js" */),
  "component---src-pages-products-act-prep-js": () => import("/Users/jamesonknoll/development/mentorverse/blueprint/blueprint-client/src/pages/products/act-prep.js" /* webpackChunkName: "component---src-pages-products-act-prep-js" */),
  "component---src-pages-products-ap-enrichment-js": () => import("/Users/jamesonknoll/development/mentorverse/blueprint/blueprint-client/src/pages/products/ap-enrichment.js" /* webpackChunkName: "component---src-pages-products-ap-enrichment-js" */),
  "component---src-pages-products-college-counseling-js": () => import("/Users/jamesonknoll/development/mentorverse/blueprint/blueprint-client/src/pages/products/college-counseling.js" /* webpackChunkName: "component---src-pages-products-college-counseling-js" */),
  "component---src-pages-products-english-language-arts-js": () => import("/Users/jamesonknoll/development/mentorverse/blueprint/blueprint-client/src/pages/products/english-language-arts.js" /* webpackChunkName: "component---src-pages-products-english-language-arts-js" */),
  "component---src-pages-products-high-school-counseling-js": () => import("/Users/jamesonknoll/development/mentorverse/blueprint/blueprint-client/src/pages/products/high-school-counseling.js" /* webpackChunkName: "component---src-pages-products-high-school-counseling-js" */),
  "component---src-pages-products-sat-2-prep-js": () => import("/Users/jamesonknoll/development/mentorverse/blueprint/blueprint-client/src/pages/products/sat-2-prep.js" /* webpackChunkName: "component---src-pages-products-sat-2-prep-js" */),
  "component---src-pages-products-sat-prep-js": () => import("/Users/jamesonknoll/development/mentorverse/blueprint/blueprint-client/src/pages/products/sat-prep.js" /* webpackChunkName: "component---src-pages-products-sat-prep-js" */),
  "component---src-pages-products-ssat-prep-js": () => import("/Users/jamesonknoll/development/mentorverse/blueprint/blueprint-client/src/pages/products/ssat-prep.js" /* webpackChunkName: "component---src-pages-products-ssat-prep-js" */),
  "component---src-pages-students-main-js": () => import("/Users/jamesonknoll/development/mentorverse/blueprint/blueprint-client/src/pages/students/main.js" /* webpackChunkName: "component---src-pages-students-main-js" */),
  "component---src-pages-team-main-js": () => import("/Users/jamesonknoll/development/mentorverse/blueprint/blueprint-client/src/pages/team/main.js" /* webpackChunkName: "component---src-pages-team-main-js" */),
  "component---src-pages-test-prep-main-js": () => import("/Users/jamesonknoll/development/mentorverse/blueprint/blueprint-client/src/pages/test-prep/main.js" /* webpackChunkName: "component---src-pages-test-prep-main-js" */)
}

